import React from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import { useLocation } from 'react-router-dom'

const Userdetail = () => {
  const location = useLocation();
  const { val } = location.state;
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-2'>
          <Sidenav />
        </div>
        <div className='col-md-10'>
          <Navbar />
          <div className='row mt-5'>
            <div className='col-md-1'></div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header card-header-icon card-header-rose">
                  <h4>User profile</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group bmd-form-group is-filled">
                          <label htmlFor="name" className="form-label">Name:</label>
                          <p>{val.name}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group bmd-form-group is-filled">
                          <label htmlFor="name" className="form-label">Password:</label>

                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="form-group bmd-form-group is-filled">
                          <label htmlFor="name" className="form-label">Phone number (optional):</label>
                          <p>{val.phone}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group bmd-form-group is-filled">
                          <label htmlFor="name" className="form-label">Email address:</label>
                          <p>{val.email}</p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="form-group bmd-form-group is-filled">
                          <label htmlFor="name" className="form-label">Street address and/or postal code (optional):</label>

                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group bmd-form-group is-filled">

                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="form-group bmd-form-group is-filled">
                          {/* <button className="btn" >Submit</button> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group bmd-form-group is-filled">

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='col-md-1'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Userdetail