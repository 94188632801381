import React, { useState } from 'react'
import Navbar from '../Template/Navbar'
import Sidenav from '../Template/Sidenav'
import Login from './LOgin'
import { useLocation, useNavigate } from 'react-router-dom'


const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state
  const usertoken = sessionStorage.getItem('token')
  const userid = sessionStorage.getItem('userid')
  const [file1, setFile1] = useState(data?.image);
  const [user, setUser] = useState({ name: data?.name, email: data?.email, phone: data?.phone, streetaddress: data?.streetaddress });

  let name, value
  const HandleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  }
  const handleSubmit = () => {
    const postURL = `https://api.pinkspot.cc/api/v1/admin/updateInfo/${userid}`
    const { name, email, phone, streetaddress } = user;
    fetch(postURL, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: name, email: email, phone: phone, streetaddress: streetaddress, image: file1 })

    })
      .then(res => {
        return res.json();
      }).then(data => {
        window.alert("profile updated");
        navigate('/dashboard');
      })
  }
  function handleChange1(e) {
    var formdata = new FormData();
    formdata.append("image", e?.target?.files[0]);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    fetch("https://api.pinkspot.cc/uploading", requestOptions)
      .then(response => response.json())
      .then(result => setFile1(result?.data?.url))
  }

  if (!usertoken) {
    return <Login />
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-2'>
          <Sidenav />
        </div>
        <div className='col-md-10'>
          <Navbar />
          <form >
            <div className='row'>
              <div className='col-md-3'></div>
              <div className='col-md-3'> </div>
              <div className='col-md-3'> </div>
              <div className='col-md-1'> </div>
              <div className='col-md-2'>
                {/* <button type="submit" className="btn ">Submit</button> */}
              </div>


            </div>
          </form>
          <div className="row mt-5">
            <div className="col-md-8">
              <div className="card">
                <div className="card-header card-header-icon card-header-rose">
                  <h4>Complete your profile</h4>
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">
                        <label htmlFor="Name" className="form-label">Name</label>
                        <input type="text" className="form-control" placeholder='Enter name' id="name" name="name" value={user.name} onChange={HandleInput} />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">
                        <label htmlFor="Password" className="form-label">Password</label>
                        <input type="Password" className="form-control" placeholder='Enter Password' id="Password" name="password" value={user.password} onChange={HandleInput} />
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">
                        <label htmlFor="Email" className="form-label">Email address</label>
                        <input type="email" className="form-control" placeholder='Enter Email' id="email" name="email" value={user.email} onChange={HandleInput} />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">
                        <label htmlFor="Phone number" className="form-label">Phone number (optional)</label>
                        <input type="Number" className="form-control" placeholder='Enter Phone number' id="phone" name="phone" value={user.phone} onChange={HandleInput} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">
                        <label htmlFor="Street" className="form-label">Street address  (optional)</label>
                        <input type="text" className="form-control" placeholder='Enter Street address' id="adress" name="streetaddress" value={user.streetaddress} onChange={HandleInput} />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    {/* <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">
                        <label htmlFor="Street" className="form-label">Street address and/or postal code (optional)</label>
                        <input type="text" className="form-control" placeholder='Enter Street address' id="adress" name="streetaddress" value={user.streetaddress} onChange={HandleInput} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">

                      </div>
                    </div> */}
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">
                        <button className="btn" onClick={() => handleSubmit()}>Submit</button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group bmd-form-group is-filled">

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card card-profile">
                <div className="card-avatar">
                  <img src={file1} alt='xyz' />
                  {/* <img src={require("../img/profile.png")} alt="sgdg" /> */}
                </div>
                <div className="card-body">
                  <h6 className="card-category text-gray">hello</h6>
                  <h4 className="card-title">hello</h4>

                  {/* <input type="hidden" name="_token" value="ebeTaYgiZfDlFn1RsBJzZVCdlL5boCRtHuvduidZ" onChange={handleChange1} /> */}
                  {/* <span className="btn btn-round btn-rose btn-file"> */}
                  {/* <span class="fileinput-exists">Change</span> */}
                  {/* <input type="file" class="image-change" name="photo" id="imgupload" required="" /> */}
                  {/* <input type="file" id="file" name="filename" id="file" name="filename" onChange={handleChange1} /> */}
                  <input type="file" id="file" name="filename" onChange={handleChange1} />
                  {/* </span> */}


                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile