import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

const Navbar = () => {
    const userid = sessionStorage.getItem('userid')
    const [post, Setpost] = useState('')
    const getpost = () => {
        fetch(`https://api.pinkspot.cc/api/v1/admin/getAdminProfileByid/${userid}`)
            .then(response => {
                return response.json()
            }).then(data => {
                Setpost(data?.data)
            })
    }
    useEffect(() => {
        getpost()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light   py-3">
                <div className="container-fluid px-5 ">
                    <Link className="navbar-brand" to="/">Super Admin Portal:</Link>


                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <img className='profile-img' src={post?.image} alt="sgdg" />
                        </ul>

                    </div>

                </div>
            </nav>

        </>
    )
}

export default Navbar