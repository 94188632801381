import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";

const Sidenav = () => {
    const navigate = useNavigate();
    const userid = sessionStorage.getItem('userid')
    const [post, Setpost] = useState('')
    const getpost = () => {
        fetch(`https://api.pinkspot.cc/api/v1/admin/getAdminProfileByid/${userid}`)
            .then(response => {
                return response.json()
            }).then(data => {
                Setpost(data?.data)
            })
    }

    useEffect(() => {
        getpost()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const logout = () => {
        sessionStorage.clear();
        navigate('/');
    }
    return (
        <>
            <nav className="navbar" style={{ background: '#69447D', minHeight: '100vh' }}>
                <img className="logo" src={require("../img/logo.png")} alt="sgdg" />
                <div className="container-fluid">
                    <ul className="navbar-nav side-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard" style={{ color: '#fff' }}><span class="material-symbols-outlined">
                                home
                            </span>Dashboard</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/category" style={{ color: '#fff' }}><span class="material-symbols-outlined">
                                category
                            </span>Category</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/post" style={{ color: '#fff' }}><span class="material-symbols-outlined">
                                post
                            </span>Post</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/users" style={{ color: '#fff' }}><span class="material-symbols-outlined">
                                group
                            </span>Users</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/profile" state={{ data: post }} style={{ color: '#fff' }}><span class="material-symbols-outlined">
                                account_circle
                            </span>Profile</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/pages"  style={{ color: '#fff' }}><span class="material-symbols-outlined">
                                account_circle
                            </span>SEO PAGE</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/form" style={{ color: '#fff' }}><span class="material-symbols-outlined">
                                description
                            </span>Form</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/" style={{ color: '#fff' }} onClick={logout}><span class="material-symbols-outlined">
                                logout
                            </span>Logout</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )

}

export default Sidenav