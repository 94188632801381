import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Category from './components/pages/Category';
import Post from './components/pages/Post';
import Users from './components/pages/Users';
import Profile from './components/pages/Profile';
import Form from './components/pages/Form';
import Postdetail from './components/pages/Postdetail';
import Login from './components/pages/LOgin';
import Formone from './components/pages/Formone';
import Formtwo from './components/pages/Formtwo';
import Formthree from './components/pages/Formthree';
import Subcategory from './components/pages/Subcategory';
import Editcategory from './components/pages/Editcategory';
import Editsubcategory from './components/pages/Editsubcategory';
import PageNotFound from './components/pages/PageNotFound';
import App from './App';
import './App.css';
import Userdetail from './components/pages/Userdetail';
import Pages from './components/pages/Pages';
import EditPages from './components/pages/EditPage';
import Editpost from './components/pages/Editpost';

const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route index path="/" element={<Login />} />
                <Route index path="/dashboard" element={<Home />} />
                <Route index path="/category" element={<Category />} />
                <Route index path="/post" element={<Post />} />
                <Route index path="/users" element={<Users />} />
                <Route index path="/profile" element={<Profile />} />
                <Route index path="/form" element={<Form />} />
                <Route index path="/view-category" element={<Subcategory />} />
                <Route index path="/Post-detail" element={<Postdetail />} />
                <Route index path="/form-one" element={<Formone />} />
                <Route index path="/form-two" element={<Formtwo />} />
                <Route index path="/form-three" element={<Formthree />} />
                <Route index path="/edit-category" element={<Editcategory />} />
                <Route index path="/edit-subcategory" element={<Editsubcategory />} />
                <Route index path="/user-detail" element={<Userdetail />} />
                <Route index path="/pages" element={<Pages />} />
                <Route index path="/editpages" element={<EditPages />} />
                <Route index path="/edit-post" element={<Editpost />} />
                <Route path='*' element={<PageNotFound />} />
            </Route>
        </Routes>
    </BrowserRouter>
);

