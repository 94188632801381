import React, { useEffect, useState } from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Login from './LOgin'

const Subcategory = () => {
    const [deleteid, Setdeleteid] = useState('')
    const usertoken = sessionStorage.getItem('token')
    const location = useLocation();
    const { id } = location.state;
    const [subcategory, Setsubcategory] = useState('')
    const [user, setUser] = useState({ name: "", iconname: "", formid: "" })
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser({ ...user, [name]: value })
    }
    const submitData = async (e) => {
        e.preventDefault();
        const { name, iconname, formid } = user;
        const data = fetch("https://api.pinkspot.cc/api/v1/category/createSubcategory", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name, iconname, categoryid: id, formid })
        });
        const response = await data;
        if (response.status === 403 || !response) {
            window.alert("invalid registration");
        } else {
            window.alert("registration sucessful");
            getsubcategory()
        }
    }
    const getsubcategory = () => {
        fetch(`https://api.pinkspot.cc/api/v1/category/getsubcategorybycatid/${id}`)
            .then(response => {
                return response.json()
            }).then(data => {
                Setsubcategory(data)
            })
    }
    useEffect(() => {
        getsubcategory();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    function deleteUser(_id) {
        window.alert("Post removed")
    }
    if (!usertoken) {
        return <Login />
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-2'>
                        <Sidenav />
                    </div>
                    <div className='col-md-10'>
                        <Navbar />
                        <div className="category-filter">
                            <form onSubmit={submitData}>
                                <div className='row'>
                                    <div className='col-md-3'>

                                        <div className="mb-3 mt-3">
                                            <input type="text" className="form-control" id="subcategory" placeholder="Add subcategory" value={user.name} name="name" onChange={handleChange} />
                                        </div>


                                    </div>
                                    <div className='col-md-3'>
                                        <div className="mb-3 mt-3">
                                            <input type="text" className="form-control" id="subcategory" placeholder="Add iconname" value={user.iconname} name="iconname" onChange={handleChange} />
                                        </div>

                                    </div>
                                    <div className='col-md-3'>
                                        <div className="mb-3 mt-3">
                                            <select className="form-select form-control" name="formid" onChange={handleChange} >
                                                <option value="form-one">form-one</option>
                                                <option value="form-two">form-two</option>
                                                <option value="form-three">form-three</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className='col-md-3'>
                                        <div className="mb-3 mt-3">
                                            <button type="submit" className="btn ">Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>

                        <div class="table-responsive text-center mt-5">
                            <table class="table ">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Sub-Category-name</th>
                                        <th>Iconname</th>

                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subcategory?.data?.map((val, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{val.name}</td>
                                                <td>{val.iconname}</td>


                                                {/* <td> <Link to="/view-category" state={{ id: val._id }}><span class="material-symbols-outlined" style={{ color: 'gray' }}>visibility</span></Link></td> */}
                                                <td> <Link to="/edit-subcategory" state={{ id: val._id, name: val.name, iconname: val.iconname, formid: val.formid }}><span class="material-symbols-outlined" style={{ color: 'gray' }}>edit</span></Link></td>
                                                <td> <span style={{ cursor: "pointer", color: 'gray' }} data-bs-toggle="modal" data-bs-target="#myModal" class="material-symbols-outlined" onClick={() => { Setdeleteid(val._id) }}>delete</span></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="myModal">
                <div className="modal-dialog">
                    <div className="modal-content">


                        <div className="modal-header">

                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>


                        <div className="modal-body">
                            Do You Want To Delete Permanently
                        </div>


                        <div className="modal-footer">
                            <button type="button" className="btn" data-bs-dismiss="modal" onClick={() => deleteUser(deleteid)}>Yes</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


export default Subcategory