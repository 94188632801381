import React from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import Login from './LOgin'
import { useLocation, useNavigate } from 'react-router-dom'

const Postdetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state;
    const usertoken = sessionStorage.getItem('token')

    const handleverified = async (id, e) => {
        const value = e.target.value;
        fetch(`https://api.pinkspot.cc/api/v1/postad/updatePostVerifyStatus/${id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isVerified: value })
    
        }).then((result) => {
          result.json().then((res) => {
            navigate(-1);
          })
        })
      };
   

    if (!usertoken) {
        return <Login />
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-2'>
                    <Sidenav />
                </div>
                <div className='col-md-10'>
                    <Navbar />
                    <div className='row'>
                        <div className='profile-text-box'>
                            <div className='profile-text'>
                                <div> {data.name && <h6>Name </h6>} <h5>{data.name} </h5></div>
                                <div> {data.availability && <h6>Availability </h6>}<h5>{data.availability} </h5></div>
                                <div>{data.haircolour && <h6>Hair Colour </h6>}<h5>{data.haircolour}</h5></div>
                                <div>{data.type && <h6>type </h6>}<h5>{data.type}</h5></div>
                                <div>{data.listedby && <h6>listedby </h6>}<h5>{data.listedby}</h5></div>
                                <div>{data.totalfloors && <h6>totalfloors </h6>}<h5>{data.totalfloors}</h5></div>
                                <div>{data.facing && <h6>facing </h6>}<h5>{data.facing}</h5></div>
                            </div>
                            <div className='profile-text'>
                                <div>{data.age && <h6>Age </h6>}<h5>{data.age} </h5></div>
                                <div> {data.height && <h6>Height </h6>}<h5>{data.height}</h5></div>
                                <div>{data.eyecolour && <h6>Eye Colour </h6>}<h5>{data.eyecolour}</h5></div>
                                <div>{data.email && <h6>email </h6>}<h5>{data.email}</h5></div>
                                <div>{data.bedroom && <h6>bedroom </h6>}<h5>{data.bedroom}</h5></div>
                                <div>{data.superbuiltuparea && <h6>superbuiltuparea </h6>}<h5>{data.superbuiltuparea}</h5></div>
                                <div>{data.floorno && <h6>floorno </h6>}<h5>{data.floorno}</h5></div>
                                <div>{data.projectname && <h6>projectname </h6>}<h5>{data.projectname}</h5></div>
                            </div>
                            <div className='profile-text'>
                                <div>{data.city && <h6>City </h6>}<h5>{data.city} </h5></div>
                                <div> {data.weight && <h6>Weight </h6>}<h5>{data.weight}</h5></div>
                                <div>{data.price && <h6>Hourly Rate $</h6>}<h5>{data.price}</h5></div>
                                <div>{data.bathroom && <h6>bathroom</h6>}<h5>{data.bathroom}</h5></div>
                                <div>{data.carpetarea && <h6>carpetarea</h6>}<h5>{data.carpetarea}</h5></div>
                                <div>{data.carparking && <h6>carparking</h6>}<h5>{data.carparking}</h5></div>
                            </div>
                            <div className='profile-text'>
                                <div>{data.ethicity && <h6>Ethnicity </h6>}<h5>{data.ethicity}</h5></div>
                                <div> {data.bodystatus && <h6>Body Stats </h6>}<h5>{data.bodystatus}</h5></div>
                                <div>{data.phone && <h6>Contact Number </h6>}<h5>{data.phone}</h5></div>
                                <div>{data.furnishing && <h6>furnishing </h6>}<h5>{data.furnishing}</h5></div>
                                <div>{data.constructionstatus && <h6>construction </h6>}<h5>{data.constructionstatus}</h5></div>
                                <div>{data.maintenance && <h6>maintenance </h6>}<h5>{data.maintenance}</h5></div>
                            </div>
                        </div>
                        <div >
                            <div className='profile-text'>
                                <h4>{data.title}</h4>
                                <p></p>
                                <p>{data.description}</p>
                            </div>
                            <div>
                                <h6>To verified or Unverified This Post click </h6>
                                <button className='btn ' value='true' onClick={(e) => handleverified(data._id, e)}>verified</button>
                                <button className='btn mx-2' value='false' onClick={(e) => handleverified(data._id, e)}>un-verified</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Postdetail