import React, { useEffect, useState } from "react";
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import Login from './LOgin'
import { Link } from "react-router-dom";

const Pages = () => {

  const usertoken = sessionStorage.getItem('token')
  const [pages, Setpages] = useState('')
 
  const getallPages = () => {
    fetch(`https://api.pinkspot.cc/api/v1/pages/getallPages`)
      .then(response => {
        return response.json()
      }).then(data => {
        Setpages(data)
      })
  }

  useEffect(() => {
    getallPages();
  }, []);



  if (!usertoken) {
    return <Login />
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-2'>
          <Sidenav />
        </div>
        <div className='col-md-10'>
          <Navbar />

         
          <div class="table-responsive text-center mt-5">
            <table class="table ">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Page Name </th>
                  <th>Seo Title</th>
                 
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pages?.data?.map((val, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{val?.pagename}</td>
                      <td>{val?.seotitle}</td>
                      <td><Link to='/editpages' className="" state={{ id: val._id,pagename:val.pagename,seotitle:val.seotitle,seodescription:val?.seodescription,seoimageurl:val?.seoimageurl,seokeyword:val?.seokeyword }}><span class="material-symbols-outlined" style={{ color: 'gray' }}>edit</span></Link></td>
                      {/* <td><Link to="/user-detail" ><span class="material-symbols-outlined" style={{ color: 'gray' }}></span></Link></td> */}
                   
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
     

    </div>
  )
}

export default Pages