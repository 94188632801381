import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({ email: "", password: "" });


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value })
    }
    const Submit = async (e) => {
        e.preventDefault();
        const { email, password } = data;


        const fetchdata = fetch("https://api.pinkspot.cc/api/v1/admin/login",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password })
            }
        )
        const response = await fetchdata;
        const res = await response.json()

        if (response.status === 200) {
            sessionStorage.setItem('token', (res.token));
            sessionStorage.setItem('userid', (res.admindata._id));
            // sessionStorage.setItem('username', (res.admindata.name));
            sessionStorage.setItem('userimage', (res.admindata.image));

            navigate('/dashboard')
        } else {
            alert("Invalid Credentials");
        }



    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-5'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <div className='signup-boxone'>
                            <img src={require("../img/logo.png")} alt="sgdg" />
                            <h4 className='text-center mt-5'>Log In</h4>
                            <div className='form'>
                                <form onSubmit={Submit}>
                                    <div className="mb-3 mt-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="email" name="email" value={data.email} onChange={handleChange} required />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="pwd" className="form-label">Password</label>
                                        <input type="password" className="form-control" id="password" name="password" value={data.password} onChange={handleChange} required />
                                    </div>


                                    {/* <div className="form-check mb-3 mt-3">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" name="remember" /> Remember me
                                        </label>
                                    </div> */}
                                    <button type="submit" className="btn submit-btn">Log In</button>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'></div>

                </div>
            </div>
        </>
    )
}

export default Login