import React from 'react'
import Navbar from '../Template/Navbar'
import Sidenav from '../Template/Sidenav'
import Login from './LOgin'

const Formtwo = () => {
  const usertoken = sessionStorage.getItem('token')
  if (!usertoken) {
    return <Login />
  }
  return (
    <>
      
      <div className='container-fluid '>
        <div className='row'>
          <div className='col-md-2'>
            <Sidenav />
          </div>
          <div className='col-md-10'>
          <Navbar />
            <div className='dashboard'>
              <>

                <div className='container mt-5'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <h6 className='post-heading text-center'>Post your Ad</h6>
                    </div>
                  </div>
                </div>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10 post-form-border'>
                      <form className='add-post' action="/action_page.php">
                        <div className="mb-3 mt-3">
                          <label htmlFor="name" className="form-label">Ad Title</label>
                          <input type="text" className="form-control" id="name" name="name" />
                        </div>
                        <div className="mb-3 mt-3">
                          <label htmlFor="name" className="form-label">Description*</label>
                          <input type="text" className="form-control" id="name" name="name" />
                        </div>
                        <div className="mb-3 mt-3">
                          <label htmlFor="name" className="form-label">Address</label>
                          <input type="text" className="form-control" id="name" name="name" />
                        </div>
                        <div className="mb-3 mt-3">
                          <label htmlFor="name" className="form-label">Phone Number</label>
                          <input type="text" className="form-control" id="name" name="name" />
                        </div>
                        <div className="mb-3 mt-3">
                          <label htmlFor="name" className="form-label">Email</label>
                          <input type="text" className="form-control" id="name" name="name" />
                        </div>
                        <div className="mb-3 mt-3">
                          <label htmlFor="name" className="form-label">Price*</label>
                          <input type="text" className="form-control" id="name" name="name" />
                        </div>
                        <div className="mb-3 mt-3 upload-photo">
                          <h6>UPLOAD UP TO 20 PHOTOS</h6>
                          <div className='row'>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>

                          </div>
                        </div>
                      </form>
                    </div>
                    {/* <div className='col-md-5'></div> */}
                    <div className='col-md-1'></div>
                  </div>
                </div>

              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Formtwo